import jstz from 'jstz';

import { toI18n } from 'util/i18n';

export const getTimeZone = () => jstz.determine().name();

const getTimeZoneOffset = () => window.currentTimeZoneOffset;

const getHBFormat = format => {
  const isHBFormat =
    window.HB && window.HB.df && window.HB.df.hasOwnProperty(format);
  return isHBFormat ? window.HB.df[format] : null;
};

const getMomentLib = () => window.moment;

export const updateLocale = (locale, localeConfig) => {
  const momentLib = getMomentLib();
  momentLib.updateLocale(locale, localeConfig);
};

export const df = formatKey => getHBFormat(formatKey);

export const STANDARD_DATE_FORMAT = df('parsable_reversed');
export const STANDARD_DATE_FORMAT_WITH_TIME_TO_MIN = `${df(
  'parsable_reversed'
)} HH:mm`;

// parseFormat must be one of:
//  1. unix (indicating unix in seconds)
//  2. a key on javascript: time in datetime.en.yml
//  3. a valid moment.js parse format (https://momentjs.com/docs/#/parsing/string-format/)
export const moment = (val, parseFormat) => {
  const momentLib = getMomentLib();

  if (!parseFormat) {
    return momentLib(val);
  }

  if (parseFormat === 'unix') {
    return momentLib.unix(val);
  }

  const hbFormat = getHBFormat(parseFormat);

  if (hbFormat) {
    return momentLib(val, hbFormat);
  }

  return momentLib(val, parseFormat);
};

export const momentWithCustomLocale = (startOfWorkWeek, val) => {
  updateLocale('en', {
    week: {
      dow: startOfWorkWeek,
    },
  });
  return moment(val);
};

export const momentInZone = (val, parseFormat, opts = {}) => {
  let mDate = moment(val, parseFormat);
  const currentTimeZoneOffset = getTimeZoneOffset();

  if (!opts.fromUTC) {
    mDate = mDate.subtract(mDate.utcOffset(), 'minutes');
  }

  return mDate.add(currentTimeZoneOffset, 'hours');
};

export const momentDate = (date, format = STANDARD_DATE_FORMAT) =>
  moment(date, format);

export const startOfWeek = (value, format = STANDARD_DATE_FORMAT) =>
  moment(value, format).startOf('week').format(format);

export const endOfWeek = (value, format = STANDARD_DATE_FORMAT) =>
  moment(value, format).endOf('week').format(format);

export const startOfHour = (value, format = STANDARD_DATE_FORMAT) =>
  moment(value, format).startOf('hour').format(format);

export const startOfCurrentWeek = (format = df('parsable_dashed')) =>
  momentInZone().startOf('week').format(format);

export const endOfCurrentWeek = (format = df('parsable_dashed')) =>
  momentInZone().endOf('week').format(format);

export const startOfMonth = (value, format = df('parsable_dashed')) =>
  moment(value, format).startOf('month').format(format);

export const endOfMonth = (value, format = df('parsable_dashed')) =>
  moment(value, format).endOf('month').format(format);

export const endOfQuarter = (format = df('parsable_dashed')) =>
  momentInZone().endOf('quarter').format(format);

export const today = (format = df('parsable_dashed')) =>
  momentInZone().format(format);

export const tomorrow = (format = df('parsable_dashed')) =>
  momentInZone().add(1, 'd').format(format);

export const yesterday = (format = df('parsable_dashed')) =>
  momentInZone().subtract(1, 'd').format(format);

export const oneWeekAgo = (format = df('parsable_dashed')) =>
  momentInZone().subtract(7, 'd').format(format);

export const twoWeeksFromToday = (format = df('parsable_dashed')) =>
  momentInZone().add(2, 'weeks').format(format);
export const duration = (...args) => getMomentLib().duration(...args);
export const range = (...args) => getMomentLib().range(...args);

export const getNow = () => new Date();
export const timestamp = () => getNow().toISOString();
export const unixTimestamp = () => getNow().getTime();

export const isToday = mDate => momentInZone().isSame(mDate, 'day');
export const isTomorrow = mDate =>
  momentInZone().add(1, 'day').isSame(mDate, 'day');
export const isYesterday = mDate =>
  momentInZone().subtract(1, 'day').isSame(mDate, 'day');

export const parsedCreatedAt = item => {
  const date =
    item.get('alert_date') || item.get('created_at') || item.get('date');
  const dateM = moment(date, df('parsable_date_time'));
  const formatKey = isToday(dateM) ? 'msg_time' : 'msg_date_time';
  const formattedDate = dateM.format(df(formatKey));

  if (isToday(dateM)) {
    return `${toI18n('time.today')}, ${formattedDate}`;
  }

  return formattedDate;
};

export const getYear = (date, parseFormat = null) =>
  momentDate(date, parseFormat).year();

export const formatDate = (date, format) => moment(date).format(df(format));
export const datePickerFormat = date =>
  moment(date).format(STANDARD_DATE_FORMAT);

export const formatDatePrettyShort = date =>
  moment(date).format(df('pretty_short_day_no_year_no_comma'));
export const formatDateReportChartAxis = date =>
  moment(date).format(df('month_day_wday'));

export const formatDateLongDate = date => moment(date).format(df('long_date'));

export const formatDatePrettyShortWithYear = date =>
  moment(date).format(df('pretty_no_wday'));

/**
 * Returns hour formatted as 'h:mm A' i.e. 5:00 AM
 * @param {string} date
 * @returns {string}
 * */
export const formatDateToMsgTime = date => moment(date).format(df('msg_time'));

export const currentTime = () => momentInZone().format(df('msg_time'));

export const monthsShort = () => getMomentLib().monthsShort();

export const workWeekContaining = (dateStrOrMoment, startOfWeekValue) => {
  const date =
    typeof dateStrOrMoment === 'string'
      ? momentDate(dateStrOrMoment)
      : dateStrOrMoment.clone();
  let offset = date.day() - startOfWeekValue;
  if (offset < 0) offset += 7;

  const start = date.subtract(offset, 'days').format(STANDARD_DATE_FORMAT);
  const end = date.add(6, 'days').format(STANDARD_DATE_FORMAT);
  return {
    start,
    end,
  };
};

export const add = (date, n, rangeName, format = STANDARD_DATE_FORMAT) =>
  momentDate(date).add(n, rangeName).format(format);

export const addDays = (date, days, format = STANDARD_DATE_FORMAT) =>
  momentDate(date).add(days, 'd').format(format);

export const datesInRange = (start, end) => {
  const dates = [];
  window.moment
    .range(momentDate(start).startOf('day'), momentDate(end).startOf('day'))
    .by('days', day => dates.push(day.format(STANDARD_DATE_FORMAT)));
  return dates;
};

export const dayOfWeekFromDate = date =>
  momentDate(date, df('parsable_reversed')).day();

export const dayOfWeekFromParsableDate = date =>
  momentDate(date, df('parsable_date')).day();

export const dayOfWeekFromParsableReversedDate = date =>
  momentDate(date, df('parsable_reversed')).day();

export const dayOfMonthFromDate = date =>
  momentDate(date, df('parsable_reversed')).date();

export const monthFromDate = date =>
  // Rails date reports months from 1, moment from 0, so adjusting here
  momentDate(date, df('parsable_reversed')).month() + 1;

export const shortDayNameMonthDayFromDate = date =>
  moment(date).format(df('pretty_short_day_no_year_no_comma'));

export const monthDayYearTimeFromDate = date =>
  moment(date).format(`${df('pretty_no_wday')} ${df('msg_time')}`);

export const isValidTime = time => /\d\d:\d\d/.test(time);

export const getTimeDifferenceInHours = (
  start,
  end,
  format = df('hours_minutes_seconds_meridian')
) => {
  if (!start || !end || !isValidTime(start) || !isValidTime(end)) return 0;

  const startTime = moment(start, format);
  const endTime = moment(end, format);
  return duration(endTime.diff(startTime)).asHours();
};

export const getTimeDifference = (start, end, period) => {
  const startTime = moment(start);
  const endTime = moment(end);
  return endTime.diff(startTime, period);
};

export const normalizeTimeString = time => {
  const timeArray = time.split(':');
  if (!time || time.length < 4 || timeArray.length < 2) return '00:00';

  if (timeArray[0].length === 1) {
    return `0${timeArray[0]}:${timeArray[1]}`;
  }
  return time;
};

export const clockFromTime = time => moment(time).format(df('time'));

export const dateFromTime = time =>
  (time || new Date().toISOString()).slice(0, 10);

export const minutesFromTime = time =>
  (time && parseInt(time.slice(14, 16), 10)) || 0;

export const displayWeekday = time => moment(time).format(df('full_wday'));

export const displayShortWeekday = time =>
  moment(time).format(df('short_wday'));

export const displayMonthDay = time =>
  moment(time).format(df('month_day_only'));

export const prettyMoreCommas = date =>
  moment(date).format(df('pretty_more_commas'));

export const displayMonthAndDay = date => moment(date).format(df('mo_day'));

export const displayTime = time =>
  minutesFromTime(time) === 0
    ? moment(time).format(df('hour12_downcase'))
    : moment(time).format(df('time12'));
// TO-DO: replace with designbase formats time_long, time_short
// double check time_short with ahellem
// config/locales/en/date_time_formats.yml

export const displayTimeRange = (startAt, endAt) =>
  `${displayTime(startAt)}-${displayTime(endAt)}`;

export const compareDateTime = (dateTime1, dateTime2) => {
  const left = new Date(dateTime1);
  const right = new Date(dateTime2);

  if (left < right) return -1;
  return left > right ? 1 : 0;
};

export const shortDayCommaMonthDateNoYear = date =>
  moment(date).format(df('pretty_short_day_no_year'));

export const shortDayCommaMonthDate = date =>
  moment(date).format(df('pretty_short_day'));

// Referenced https://stackoverflow.com/a/325964
export const isDateRangesOverlapping = (
  [start1, end1],
  [start2, end2],
  isInclusive = false
) => {
  if (isInclusive) {
    return (
      new Date(start1) <= new Date(end2) && new Date(end1) >= new Date(start2)
    );
  }
  return new Date(start1) < new Date(end2) && new Date(end1) > new Date(start2);
};

export const isDateAfterNow = date =>
  date ? compareDateTime(date, today(STANDARD_DATE_FORMAT)) > 0 : false;

export const daysDiffFromToday = dateToCompare =>
  moment(today(), 'MM-DD-YYYY').diff(moment(dateToCompare), 'days');

// returns the difference between 2 dates with dateToCompare being a future date
export const daysDiffToToday = dateToCompare =>
  moment(dateToCompare).diff(moment(today(), 'MM-DD-YYYY'), 'days');

export const noOfDays = (startDate, endDate = moment()) =>
  moment(endDate).diff(moment(startDate), 'days');

export const startOfWeekDate = () => momentInZone().startOf('week');
export const startOfMonthDate = () => momentInZone().startOf('month');
export const endOfWeekDate = () => momentInZone().endOf('week');
export const endOfMonthDate = () => momentInZone().endOf('month');
export const addWeeks = (date, weeks) => moment(date).add(weeks, 'weeks');
export const addMonths = (date, months) => moment(date).add(months, 'months');
export const isFutureDate = date => moment(date).isAfter(moment().endOf('day'));

export const convertWeekdayNumberToLongFormat = weekdayNumber => {
  if (weekdayNumber < 0 || weekdayNumber > 6) {
    return 'Invalid weekday number';
  }

  const randomDateInWeek = moment().day(weekdayNumber);
  return randomDateInWeek.format('dddd');
};

export const isValidDate = date => moment(date).isValid();

export const getCurrentYearSecondFriday = () =>
  moment().endOf('year').startOf('month').day(12);

export const getCurrentYearLastFriday = () =>
  moment().endOf('year').endOf('month').day(-2);

export const getCurrentYearFirstDay = () =>
  moment().startOf('year').startOf('month');
