import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const Role = createResource({
  name: 'role',
  path: '/schedule_builder/roles',
  schema: {
    attributes: ['name', 'color'],
    relationships: {
      department: {
        relationshipType: BELONGS_TO,
        inverseOf: 'roles',
      },
      roleLaborTotals: {
        relationshipType: HAS_MANY,
        inverseOf: 'role',
      },
      shifts: {
        relationshipType: HAS_MANY,
        inverseOf: 'role',
      },
    },
  },
});

export default Role;
