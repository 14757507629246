/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { actionTypes } from 'actions/session';

export interface Plan {
  tier_id: number;
  tier_name: string;
  savings: number | null;
  suggested_plan: boolean;
  billing_frequency: string;
  downsell_tier_name: string;
  monthly_base_amount: number;
  monthly_discounted_amount: number;
}

function updateSessionMatcher(action: any): action is PayloadAction<{
  tierProducts: { billed_annually: boolean; monthly: Plan[]; annual: Plan[] };
}> {
  return action.type === actionTypes.UPDATE_SESSION;
}

import {
  fetchFeatures,
  fetchProducts,
  fetchProductTaskManager,
  fetchProductTipManager,
  fetchTaskManagerSubscriptions,
  fetchTipManagerSubscriptions,
} from './actions';
import { getBillingCycleIsAnnual } from './util';

export const initialState = {
  monthlyProducts: [] as Plan[],
  annualProducts: [] as Plan[],
  billed_annually: null,
  isSelectedBillingCycleAnnual: false,
  features: [] as any[],
  taskManager: {
    isLoading: false,
    isSubscribed: false,
    price: { amountInCents: 0, priceSystemId: '' } as any,
  },
  tipManager: {
    isLoading: false,
    isSubscribed: false,
    price: { amountInCents: 0, priceSystemId: '' } as any,
  },
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    toggleBillingFrequency: (state, { payload }) => {
      state.isSelectedBillingCycleAnnual = payload === 'annual';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, state => {
        state.monthlyProducts = [];
        state.annualProducts = [];
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.monthlyProducts = action.payload.monthly;
        state.annualProducts = action.payload.annual;
        state.isSelectedBillingCycleAnnual = getBillingCycleIsAnnual(
          action.payload
        );
      })
      .addCase(fetchFeatures.pending, state => {
        state.features = [];
      })
      .addCase(fetchFeatures.fulfilled, (state, action) => {
        state.features = action.payload.features;
      })
      .addCase(fetchTaskManagerSubscriptions.pending, state => {
        state.taskManager.isLoading = true;
      })
      .addCase(fetchTaskManagerSubscriptions.fulfilled, (state, action) => {
        const activeSubscription = action.payload.subscriptions.find(
          subscription => isNull(subscription?.archived_at)
        );
        if (activeSubscription) {
          state.taskManager.price.amountInCents =
            activeSubscription.price_in_cents;
        }
        state.taskManager.isSubscribed = Boolean(activeSubscription);
        state.taskManager.isLoading = false;
      })
      .addCase(fetchProductTaskManager.rejected, state => {
        state.taskManager.isLoading = false;
      })
      .addCase(fetchTipManagerSubscriptions.pending, state => {
        state.tipManager.isLoading = true;
      })
      .addCase(fetchTipManagerSubscriptions.fulfilled, (state, action) => {
        const activeSubscription = action.payload.subscriptions.find(
          subscription => isNull(subscription?.archived_at)
        );
        if (activeSubscription) {
          state.tipManager.price.amountInCents =
            activeSubscription.price_in_cents;
        }
        state.tipManager.isSubscribed = Boolean(activeSubscription);
        state.tipManager.isLoading = false;
      })
      .addCase(fetchProductTaskManager.fulfilled, (state, action) => {
        // Will be empty if already subscribed
        if (!action.payload.products?.[0]?.price?.length) return;

        const price = action.payload.products[0].price[0];
        state.taskManager.price.amountInCents = price.amount_in_cents;
        state.taskManager.price.priceSystemId = price.system_id;
      })
      .addCase(fetchProductTipManager.fulfilled, (state, action) => {
        // Will be empty if already subscribed
        if (!action.payload.products?.[0]?.price?.length) return;

        const price = action.payload.products[0].price[0];
        state.tipManager.price.amountInCents = price.amount_in_cents;
        state.tipManager.price.priceSystemId = price.system_id;
      })
      .addCase(fetchProductTipManager.rejected, state => {
        state.taskManager.isLoading = false;
      })
      .addMatcher(updateSessionMatcher, (state, action) => {
        const { tierProducts } = action.payload;
        if (!tierProducts) return state;

        state.monthlyProducts = tierProducts.monthly;
        state.annualProducts = tierProducts.annual;
        state.isSelectedBillingCycleAnnual =
          getBillingCycleIsAnnual(tierProducts);
      });
  },
});

export const actions = productsSlice.actions;
export const reducer = productsSlice.reducer;
