import { combineReducers } from '@reduxjs/toolkit';

import { reducer as downgradeWorkflowData } from './DowngradeBottomDrawer/slice';
import { reducer as nextIntegration } from './NextIntegrationDialog/slice';
import taskManagerCheckoutDrawer from './TaskManagerCheckoutDrawer/slice';
import tipManagerCheckoutDrawer from './TipManagerCheckoutDrawer/slice';

export const reducer = combineReducers({
  downgradeWorkflowData,
  nextIntegration,
  tipManagerCheckoutDrawer,
  taskManagerCheckoutDrawer,
});
