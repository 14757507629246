import { MaxRange } from 'fe-design-base/organisms/FDBRangePicker/types';

import {
  addMonths,
  addWeeks,
  endOfMonthDate,
  endOfWeekDate,
  momentInZone,
  startOfMonthDate,
  startOfWeekDate,
} from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const ONE_WEEK_MAX = { amount: 1, unit: 'weeks' } as MaxRange;
export const ONE_MONTH_MAX = { amount: 1, unit: 'months' } as MaxRange;
export const ONE_YEAR_MAX = { amount: 1, unit: 'years' } as MaxRange;

export const RANGE_PICKER_WIDTH = '284px';

export const CUSTOM_LABEL = toI18n(
  'fe_design_base.range_picker.presets.custom'
);

export const CUSTOM_PRESET_TEXT =
  'fe_design_base.range_picker.custom_preset_text';

export const getLastWeek = () => ({
  label: toI18n('fe_design_base.range_picker.presets.last_week'),
  startDate: addWeeks(startOfWeekDate(), -1),
  endDate: addWeeks(endOfWeekDate(), -1),
}); // 1 week range starting from previous week from the current week
export const getThisWeek = () => ({
  label: toI18n('fe_design_base.range_picker.presets.this_week'),
  startDate: startOfWeekDate(),
  endDate: endOfWeekDate(),
}); // current week (starting from Sunday || startOfWorkWeek)

export const getNextWeek = () => ({
  label: toI18n('fe_design_base.range_picker.presets.next_week'),
  startDate: addWeeks(startOfWeekDate(), 1),
  endDate: addWeeks(endOfWeekDate(), 1),
}); // 1 week range starting from the next week

export const getNext2Weeks = () => ({
  label: toI18n('fe_design_base.range_picker.presets.next_2_weeks'),
  startDate: addWeeks(startOfWeekDate(), 1),
  endDate: addWeeks(endOfWeekDate(), 2),
}); // 2 week range starting from the next week

export const getLastMonth = () => ({
  label: toI18n('fe_design_base.range_picker.presets.last_month'),
  startDate: startOfMonthDate().subtract(1, 'month').startOf('month'),
  endDate: startOfMonthDate().subtract(1, 'month').endOf('month'),
}); // 1 month range of previous month

export const getThisMonth = () => ({
  label: toI18n('fe_design_base.range_picker.presets.this_month'),
  startDate: startOfMonthDate(),
  endDate: endOfMonthDate(),
}); // 1 month range of current month

export const getNextMonth = () => ({
  label: toI18n('fe_design_base.range_picker.presets.next_month'),
  startDate: addMonths(startOfMonthDate(), 1),
  // Note: template literal needed to resolve existing Storybook build error when using
  // '.add' method https://github.com/storybookjs/storybook/issues/12208#issuecomment-1397300602
  endDate: endOfMonthDate().add(`${1}`, 'day').endOf('month'),
}); // 1 month range of next month

export const getToday = () => ({
  label: toI18n('fe_design_base.range_picker.presets.today'),
  startDate: momentInZone(),
  endDate: momentInZone(),
});

export const getYesterday = () => ({
  label: toI18n('fe_design_base.range_picker.presets.yesterday'),
  startDate: momentInZone().subtract(1, 'day').startOf('day'),
  endDate: momentInZone().subtract(1, 'day').endOf('day'),
});
// TODO: replace constants below with functional versions as rangepickers are updated
// below constants used for react-dates range pickers
export const THIS_WEEK = {
  label: toI18n('fe_design_base.range_picker.presets.this_week'),
  startDate: startOfWeekDate(),
  endDate: endOfWeekDate(),
}; // current week (starting from Sunday || startOfWorkWeek)

export const NEXT_WEEK = {
  label: toI18n('fe_design_base.range_picker.presets.next_week'),
  startDate: addWeeks(startOfWeekDate(), 1),
  endDate: addWeeks(endOfWeekDate(), 1),
}; // 1 week range starting from the next week

export const NEXT_2_WEEKS = {
  label: toI18n('fe_design_base.range_picker.presets.next_2_weeks'),
  startDate: addWeeks(startOfWeekDate(), 1),
  endDate: addWeeks(endOfWeekDate(), 2),
}; // 2 week range starting from the next week

export const LAST_WEEK = {
  label: toI18n('fe_design_base.range_picker.presets.last_week'),
  startDate: addWeeks(startOfWeekDate(), -1),
  endDate: addWeeks(endOfWeekDate(), -1),
}; // 1 week range starting from previous week from the current week

export const THIS_MONTH = {
  label: toI18n('fe_design_base.range_picker.presets.this_month'),
  startDate: startOfMonthDate(),
  endDate: endOfMonthDate(),
}; // 1 month range of current month

export const LAST_MONTH = {
  label: toI18n('fe_design_base.range_picker.presets.last_month'),
  startDate: startOfMonthDate().subtract(1, 'month').startOf('month'),
  endDate: startOfMonthDate().subtract(1, 'month').endOf('month'),
}; // 1 month range of previous month

export const NEXT_MONTH = {
  label: toI18n('fe_design_base.range_picker.presets.next_month'),
  startDate: addMonths(startOfMonthDate(), 1),
  // Note: template literal needed to resolve existing Storybook build error when using
  // '.add' method https://github.com/storybookjs/storybook/issues/12208#issuecomment-1397300602
  endDate: endOfMonthDate().add(`${1}`, 'day').endOf('month'),
}; // 1 month range of next month

export const TODAY = {
  label: toI18n('fe_design_base.range_picker.presets.today'),
  startDate: momentInZone(),
  endDate: momentInZone(),
};

export const YESTERDAY = {
  label: toI18n('fe_design_base.range_picker.presets.yesterday'),
  startDate: momentInZone().subtract(1, 'day').startOf('day'),
  endDate: momentInZone().subtract(1, 'day').endOf('day'),
};
