import React, { useCallback } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import BoxV2 from 'fe-design-base/atoms/Box';
import PillV2 from 'fe-design-base/molecules/Pill';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import { TabletAndBelow } from 'components/MediaQuery';
import Pill from 'components/Pill';
import Text from 'components/Text';

import { getCookie } from 'util/cookies';
import { toI18n } from 'util/i18n';
import { setOriginContextByUrl } from 'util/tracking';
import { PRODUCT_AREAS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import Icon from './Icon';
import IconMenuItem from './IconMenuItem';
import { checkHighlightTopMenu } from './utils';

const IconButton = ({ iconType, color }) => (
  <Box mr={16} tag="span">
    <Icon type={iconType} color={color} />
  </Box>
);

const TopMenu = ({
  item,
  cxEl,
  sizes,
  collapsedState,
  collapseLeftNav,
  onClickFunction,
  avatarUrl,
  shouldShowEnforcementDialog,
  onShowEnforcementDialog,
  canCloseEnforcementDialog,
}) => {
  const userLanguage = getCookie('user_language');
  const highlight =
    collapsedState ||
    checkHighlightTopMenu(item, window.location.pathname) ||
    !item.get('sub_items') ||
    item.get('sub_items').size === 0;
  const active = item.get('active');
  const color = active ? 'purple' : 'neutral700';
  const newBadge = item.get('new_badge');
  const offerBadge = item.get('offer_badge') && userLanguage !== 'es';
  const iconType = item.get('icon');

  const secIconType = item.get('secondary_icon');
  const url = item.get('url');
  const trackUxEvent = useTrackUx();

  const handleClick = useCallback(
    (mobile, e) => {
      e.preventDefault();

      trackUxEvent(item.get('event_action'), TRACK_ACTION_TYPES.CLICK, {
        link_text: `${item.get('name')}`,
        element: `${item.get('name').toLowerCase()} link`,
      });

      setOriginContextByUrl(url);

      window.Homebase.RailsReactBridge.navigateToReactView(
        e.currentTarget.getAttribute('href')
      );

      if (mobile) {
        collapseLeftNav();
      }

      if (item.get('name') !== 'Team' && shouldShowEnforcementDialog) {
        onShowEnforcementDialog({
          productArea: PRODUCT_AREAS.NAVIGATION,
          canCloseDialog: canCloseEnforcementDialog,
        });
      }
    },
    [
      trackUxEvent,
      item,
      url,
      collapseLeftNav,
      shouldShowEnforcementDialog,
      onShowEnforcementDialog,
      canCloseEnforcementDialog,
    ]
  );

  return (
    <TabletAndBelow>
      {mobile => (
        <a
          href={item.get('url')}
          onClick={onClickFunction || partial(handleClick, mobile)}
          className={cxEl('top-menu', { active: item.get('active') })}
        >
          <Box
            pv={14}
            pl={sizes.leftPadding - (highlight && active ? 12 : 0)}
            row
            vcenter
            pointer
            tag="span"
            bg={highlight && active ? 'lilac' : null}
            bl={highlight && active ? '12px solid purple' : null}
            borderbox
            noWrap
            style={
              highlight && active ? { borderRadius: '0 20px 20px 0' } : null
            }
          >
            <IconMenuItem
              cxEl={cxEl}
              iconType={iconType}
              color={color}
              avatarUrl={avatarUrl}
              itemName={item.get('name')}
              collapsedState={collapsedState}
            />

            {!collapsedState && (
              <>
                <Box tag="span" vcenter>
                  <Text fs={sizes.topMenuFontSize} fw600 color={color}>
                    {item.get('name')}
                  </Text>
                </Box>
                {secIconType && (
                  <IconButton iconType={secIconType} color={color} />
                )}
                {newBadge && (
                  <Pill
                    color="blue"
                    i18n="left_nav.new_badge"
                    size="xs"
                    inline
                    ml8
                    bradius="3px"
                    pa4
                    style={{ fontSize: '10px' }}
                  />
                )}
                {offerBadge && (
                  <BoxV2 className={cxEl('pill')} m="0 auto">
                    <PillV2 variant="highlight">
                      {toI18n('left_nav.offer_badge')}
                    </PillV2>
                  </BoxV2>
                )}
              </>
            )}
          </Box>
        </a>
      )}
    </TabletAndBelow>
  );
};

TopMenu.propTypes = {
  item: ImmutablePropTypes.map.isRequired,
  cxEl: PropTypes.func.isRequired,
  sizes: PropTypes.shape({
    leftPadding: PropTypes.number,
    topMenuFontSize: PropTypes.number,
  }),
  collapsedState: PropTypes.bool,
  collapseLeftNav: PropTypes.func,
  onClickFunction: PropTypes.func,
  avatarUrl: PropTypes.string,
  shouldShowEnforcementDialog: PropTypes.bool.isRequired,
  onShowEnforcementDialog: PropTypes.func.isRequired,
};

export default TopMenu;
