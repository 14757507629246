import colors from 'fe-design-base/styles/colors';

const appearance = {
  theme: 'stripe',
  variables: {
    fontWeightNormal: '500',
    borderRadius: '4px',
    colorPrimary: colors.purple300,
    gridRowSpacing: '16px',
  },
  rules: {
    '.Input, .Block, .CheckboxInput, .CodeInput': {
      boxShadow: 'none',
    },
    '.Input::placeholder': {
      color: colors.mono500,
    },
    '.Label': {
      fontSize: '14px',
      marginBottom: '8px',
      fontWeight: '700',
      color: colors.mono700,
    },
    '.Block': {
      borderColor: 'transparent',
    },
    '.BlockDivider': {
      backgroundColor: colors.mono300,
    },
    '.Tab, .Tab:hover, .Tab:focus': {
      border: '0',
    },
    '.Tab--selected, .Tab--selected:hover': {
      backgroundColor: colors.purple300,
      color: colors.mono0,
    },
  },
};

export const MODULE_OPTIONS = {
  mode: 'setup',
  currency: 'usd',
  paymentMethodCreation: 'manual',
  paymentMethodOrder: ['card'],
  fields: {
    billingDetails: {
      name: 'auto',
      address: {
        country: 'never',
        postalCode: 'auto',
      },
    },
  },
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  appearance,
};

export const PURCHASE_MODULE_OPTIONS = {
  mode: 'subscription',
  amount: 2500,
  currency: 'usd',
  fields: {
    billingDetails: {
      name: 'auto',
      address: {
        country: 'never',
        postalCode: 'auto',
      },
    },
  },
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  appearance,
};

const oneTimePurchaseModuleAppearance = {
  theme: 'stripe',
  variables: {
    fontWeightNormal: '500',
    borderRadius: '4px',
    colorPrimary: colors.purple300,
    gridRowSpacing: '16px',
  },
  rules: {
    '.Input, .Block, .CheckboxInput, .CodeInput': {
      boxShadow: 'none',
    },
    '.Input::placeholder': {
      color: colors.mono500,
    },
    '.Label': {
      fontSize: '14px',
      marginBottom: '8px',
      fontWeight: '700',
      color: colors.mono700,
    },
    '.Block': {
      borderColor: 'transparent',
    },
    '.BlockDivider': {
      backgroundColor: colors.mono300,
    },
    '.Tab, .Tab:hover, .Tab:focus': {
      border: '0',
    },
    '.Tab--selected, .Tab--selected:hover': {
      backgroundColor: colors.purple300,
      color: colors.mono0,
    },
  },
};

export const ONE_TIME_PURCHASE_MODULE_OPTIONS = {
  mode: 'payment',
  amount: 3000,
  currency: 'usd',
  setupFutureUsage: 'off_session',
  fields: {
    billingDetails: {
      name: 'auto',
      address: {
        country: 'never',
        postalCode: 'auto',
      },
    },
  },
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  oneTimePurchaseModuleAppearance,
};
