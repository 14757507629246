import { toI18n } from 'util/i18n';

export const successData = [
  {
    primary: toI18n('checkout_drawer.task_manager.success.point_1'),
  },
  {
    primary: toI18n('checkout_drawer.task_manager.success.point_2'),
  },
  {
    primary: toI18n('checkout_drawer.task_manager.success.point_3'),
  },
];
