import React from 'react';
import MUIDivider from '@mui/material/Divider';
import withCx, { CxProps } from 'fe-core/util/withCx';
import colors from 'fe-design-base/styles/colors';
import { ColorProp } from 'fe-design-base/types';

interface DividerProps {
  orientation?: 'horizontal' | 'vertical';
  type?: 'light' | 'medium' | 'strong';
  color?: ColorProp;
  spacing?: number;
}

const Divider = ({
  orientation = 'horizontal',
  type = 'medium',
  color,
  spacing = 2,
  cx,
}: DividerProps & CxProps) => {
  const isVertical = orientation === 'vertical';

  let borderBottomWidth = '0.5px';
  let borderRightWidth = '0.5px';
  if (type === 'medium') {
    borderBottomWidth = '1px';
    borderRightWidth = '1px';
  }
  if (type === 'strong') {
    borderBottomWidth = '2px';
    borderRightWidth = '2px';
  }

  const defaultBorderColor =
    isVertical && type === 'strong' ? colors.mono500 : colors.mono300;
  const verticalMargin = isVertical ? 0 : `${spacing}px`;
  const horizontalMargin = isVertical ? `${spacing}px` : 0;

  return (
    <MUIDivider
      className={cx()}
      flexItem
      orientation={orientation}
      variant="fullWidth"
      sx={{
        [`&.FDBDivider.MuiDivider-root`]: {
          border: `0px solid ${color ? colors[color] : defaultBorderColor}`,
          borderBottomWidth: isVertical ? 0 : borderBottomWidth,
          borderRightWidth: isVertical ? borderRightWidth : 0,
          margin: `${verticalMargin} ${horizontalMargin}`,
        },
      }}
    />
  );
};

export default withCx<DividerProps>('FDBDivider')(Divider);
