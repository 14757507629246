import { fromJS, Map, OrderedMap } from 'immutable';
import { uniqueId } from 'lodash';

import { actionTypes as addTeamActionTypes } from 'actions/addTeam';
import { actionTypes as timesheetsActionTypes } from 'actions/timesheets';
const INITIAL_EMPLOYEE_VALUES = Map({
  name: null,
  email: null,
  phone: null,
  wage_rate: null,
  contact_info: null,
});

const buildNewEmployee = () =>
  Map({ values: INITIAL_EMPLOYEE_VALUES, id: uniqueId() });

const buildNewEmployees = num => {
  let newEmployees = OrderedMap();

  [...Array(num)].forEach(() => {
    const newEmployee = buildNewEmployee();
    newEmployees = newEmployees.set(newEmployee.get('id'), newEmployee);
  });

  return newEmployees;
};

const INITIAL_STATE = fromJS({
  employees: buildNewEmployees(3),
  matchingUser: null,
  addEmployeeWarningModalOpen: false,
  syncingEmployees: false,
  updatingTeam: false,
  companyRolesLoading: false,
  companyRoles: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case addTeamActionTypes.FETCH_COMPANY_ROLES_REQUEST:
      return state.merge({ companyRolesLoading: true });
    case addTeamActionTypes.FETCH_COMPANY_ROLES_FAILURE:
      return state.merge({ companyRolesLoading: false });
    case addTeamActionTypes.FETCH_COMPANY_ROLES_SUCCESS:
      return state.merge({
        companyRolesLoading: false,
        companyRoles: fromJS(action.payload),
      });
    case timesheetsActionTypes.ADD_LOCATION_ROLE_SUCCESS:
      if (state.get('companyRoles').size === 0) {
        // if there are no roles, we will fetch all the roles on the schedule builder page
        return state;
      }

      return state.merge({
        companyRoles: state.get('companyRoles').push(fromJS(action.payload)),
      });
    case addTeamActionTypes.CLOSE_DRAWER:
    case addTeamActionTypes.TOGGLE_UPDATE_TEAM_DRAWER:
      return state.merge({
        employees: INITIAL_STATE.get('employees'),
      });

    case addTeamActionTypes.UPDATE_EMPLOYEE: {
      if (
        !state.get('updatingTeam') &&
        state.get('employees').every(emp => emp.getIn(['values', 'name']))
      ) {
        return state.mergeIn(['employees'], buildNewEmployees(1));
      }
      return state.mergeIn(
        ['employees', action.payload.id, 'values'],
        action.payload.attrs
      );
    }

    case addTeamActionTypes.CREATE_EMPLOYEES_SUCCESS:
    case addTeamActionTypes.UPDATE_EMPLOYEES_SUCCESS:
      return INITIAL_STATE;

    case addTeamActionTypes.TOGGLE_ADD_EMPLOYEE_WARNING_MODAL_OPEN:
      return state.merge({
        addEmployeeWarningModalOpen: action.payload.modalOpen,
      });

    case addTeamActionTypes.SYNC_EMPLOYEES_REQUEST:
      return state.merge({
        syncingEmployees: true,
      });

    case addTeamActionTypes.SYNC_EMPLOYEES_SUCCESS:
    case addTeamActionTypes.SYNC_EMPLOYEES_FAILURE:
      return state.merge({
        syncingEmployees: false,
      });

    default:
      return state;
  }
};
