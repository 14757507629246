import { IllustrationProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

const ConfirmationSuccess = ({
  alt = 'Confirmation Success Illustration',
  ...props
}: IllustrationProps) => (
  <Box
    w={72}
    {...props}
    minw={72}
    className={`FDBIllustration${
      props?.className ? ` ${props.className}` : ''
    }`}
  >
    <img
      src={require('./imageFiles/confirmation_success.svg')}
      style={{ width: '100%' }}
      alt={alt}
    />
  </Box>
);
export default ConfirmationSuccess;
