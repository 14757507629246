import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';

const DOUBLE_CURLY_BRACES_REGEX = /\{\{\s*(.*?)\s*\}\}/g;

const IncognitoModeAlert = () => {
  const heading = toI18n('integrations.common.clover_incognito.heading');
  const escapeHeading = heading.replace(DOUBLE_CURLY_BRACES_REGEX, '{{$1}}');

  return (
    <Box
      tcenter
      column
      justifyContentCenter
      gap={8}
      maxw={395}
      h="100vh"
      mh="auto"
    >
      <Text variant="heading3" color="mono900">
        {escapeHeading}
      </Text>
      <Text
        variant="body"
        color="mono700"
        i18n="integrations.common.clover_incognito.body"
      />
    </Box>
  );
};

export default IncognitoModeAlert;
