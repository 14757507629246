import React from 'react';
import Box from 'fe-components/Box';
import Chip from 'fe-design-base/molecules/Chip';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('ChipSelection');

const ChipGroup = ({ chipOptions, selectedValue, onClick }) => (
  <Box row className={cx()}>
    {chipOptions.map((option, i) => (
      <Box key={`option-${i}`} mr={8} mb={20}>
        <Chip
          value={option.value}
          selected={capitalize(option.value) === capitalize(selectedValue)}
          onClick={onClick}
        >
          {option.label}
        </Chip>
      </Box>
    ))}
  </Box>
);

ChipGroup.propTypes = {
  chipOptions: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChipGroup;
