import './Avatar.scss';

import React, { PureComponent } from 'react';
import { keys } from 'lodash';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

import cxHelpers from 'util/className';
import getIsEmbedded from 'util/embedded';

const DEFAULT_SIZE = 24;

@cxHelpers('Avatar')
class Avatar extends PureComponent {
  static propTypes = {
    url: PropTypes.string,
    size: PropTypes.number,
    border: PropTypes.oneOf(keys(colors)),
    borderWidth: PropTypes.number,
    shadow: PropTypes.bool,
  };

  static defaultProps = {
    size: DEFAULT_SIZE,
    borderWidth: 2,
  };

  isEmbedded = getIsEmbedded();

  render() {
    const { url, size, border, borderWidth } = this.props;

    const style = { width: size, height: size, ...this.props.style };

    if (border) {
      style.border = `${borderWidth}px solid ${colors[border]}`;
    }

    return (
      <img
        className={this.cx({
          shadow: this.props.shadow,
          isEmbedded: this.isEmbedded,
        })}
        style={style}
        src={url}
        alt="avatar"
      />
    );
  }
}

export default Avatar;
