import React, { useCallback } from 'react';
import ConfirmationSuccess from 'fe-design-base/assets/illustrations/ConfirmationSuccess';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import List from 'fe-design-base/molecules/List/List';

import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx, useTrackUxOnMount } from 'util/uxEvents';

import { successData } from './util';

interface SuccessProps {
  closeDrawer: () => void;
}

const Success = ({ closeDrawer }: SuccessProps) => {
  const trackUx = useTrackUx();

  useTrackUxOnMount(EVENT_ACTIONS.SIDE_DRAWER_VIEWED, TRACK_ACTION_TYPES.VIEW, {
    stage: 'checkout_completed',
  });

  const handleOnClickPrimary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: toI18n('checkout_drawer.task_manager.success.cta.primary'),
      element: 'primary_cta',
      stage: 'checkout_completed',
    });
    closeDrawer?.();
  }, [closeDrawer, trackUx]);

  return (
    <Box mt={122}>
      <Box hcenter>
        <ConfirmationSuccess w={164} h={164} />;
      </Box>
      <Box gap={24} column mt={40}>
        <Text
          variant="heading2"
          i18n="checkout_drawer.task_manager.success.title"
        />
        <List
          title={toI18n('checkout_drawer.task_manager.success.list_title')}
          size="small"
          data={successData}
        />
      </Box>
      <Box gap={16} mt={56} column>
        <Button
          variant="primary"
          linkTo="/task_manager"
          onClick={handleOnClickPrimary}
        >
          {toI18n('checkout_drawer.task_manager.success.cta.primary')}
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
