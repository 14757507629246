import { createResource } from 'fe-core/Resources';

const CompanyLaborHours = createResource({
  name: 'CompanyLaborHours',
  path: '/schedule_builder/labor/company_hours',
  schema: {
    attributes: ['job_id', 'total_labor_hours'],
    relationships: {},
  },
});

export default CompanyLaborHours;
