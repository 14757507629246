import { BELONGS_TO } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const RoleLaborTotals = createResource({
  name: 'roleLaborTotals',
  path: '/schedule_builder/labor',
  schema: {
    attributes: [
      'hours',
      'has_overtime',
      'wages',
      'date',
      'start_date',
      'end_date',
      'job_ids',
    ],
    relationships: {
      role: {
        relationshipType: BELONGS_TO,
        inverseOf: 'roleLaborTotals',
      },
      job: {
        relationshipType: BELONGS_TO,
        inverseOf: 'roleLaborTotals',
      },
    },
  },
});

export default RoleLaborTotals;
