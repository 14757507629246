import { RootState } from 'initializers/types';

import { SLICE_NAME } from './constants';

export const selectIsToastOpen = (state: RootState) =>
  state.get(SLICE_NAME).isToastOpen;

export const selectToastContent = (state: RootState) =>
  state.get(SLICE_NAME).content;

export const selectToastProps = (state: RootState) =>
  state.get(SLICE_NAME).toastProps;
