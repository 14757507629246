// If you are adding a new Banner/Footer/Loader/Drawer and don't need a redux action to fire
// create your *Global* component here.
// If you are looking to create a new modal, please follow the patterns laid-out
// in /modals/RootModal/RootModal.jsx
import { Fragment, lazy, PureComponent, Suspense } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { scheduleBuilderRoute, timesheetsRoute } from 'api/navigation';
import PropTypes from 'prop-types';

import { setAnnouncementCanShowToFalse } from 'actions/session';

import { getShowAddTeamDrawers } from 'selectors/addTeam';
import {
  getShowPayAnywhereTimesheetsModal,
  getShowQuickbooksAuthModal,
} from 'selectors/ftu';
import {
  getBannerAnnouncementForCurrentPath,
  getCurrentUser,
  getModalAnnouncementForCurrentPath,
  getShowPasswordResetModal,
} from 'selectors/session';

import AddEmployeeWarningModal from 'features/addTeam/AddEmployeeWarningModal';
import ChooseHowDrawer from 'features/addTeam/ChooseHowDrawer';
import AnnouncementBanner from 'features/banners/AnnouncementBanner';
import { selectShouldShowPastDueSubscriptionDialog } from 'features/biller/selectors';
import QuickbooksAuthModal from 'features/ftu/QuickbooksAuthModal';
import PasswordResetModal from 'features/modals/PasswordResetModal';
import NextIntegrationDialog from 'features/monetization/NextIntegrationDialog/NextIntegrationDialog';
import { selectShouldShowNextIntegrationDialog } from 'features/monetization/NextIntegrationDialog/selectors';
import TaskManagerCheckoutDrawer from 'features/monetization/TaskManagerCheckoutDrawer';
import { selectTaskManagerCheckoutDrawerIsOpen } from 'features/monetization/TaskManagerCheckoutDrawer/selectors';
import TipManagerCheckoutDrawer from 'features/monetization/TipManagerCheckoutDrawer';
import { selectTipManagerCheckoutDrawerIsOpen } from 'features/monetization/TipManagerCheckoutDrawer/selectors';
import NotificationsView from 'features/notifications/NotificationsView';
import { selectShowC2CDialogFromURL } from 'features/productGrowth/selectors';
import AddTeamDrawer from 'features/team/drawers/AddTeamDrawer';

import ActionsListDrawer from 'components/ActionsListDrawer';
import BottomMobileInterstitial from 'components/BottomMobileInterstitial';
import BottomSheetDialog from 'components/BottomSheetDialog';
import AnnouncementModal from 'components/modals/AnnouncementModal';

import cxHelpers from 'util/className';

/* If your new component is increasing the main app bundle size more than 0.1 MB and does
 * not need to be loaded on the page immediately, consider lazy loading. To test the
 * production bundle run 'yarn build' and review size of 'app-bundle-{hash}.js'.
 * We should aim to keep the main app bundle under 2.3 MB'
 */
const ClickToCallDialog = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "ClickToCallDialog" */ 'features/monetization/ClickToCallDialog/ClickToCallDialog'
  )
);
const PastDueSubscriptionDialog = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "PastDueSubscriptionDialog" */ '../../monetization/PastDueSubscriptionDialog/PastDueSubscriptionDialog'
  )
);
const PayAnywhereTimesheetsModal = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "PayAnywhereTimesheetsModal" */ '../../ftu/PayAnywhereTimesheetsModal'
  )
);
const AddEmployeeSuccessModal = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "AddEmployeeSuccessModal" */ 'features/team/components/AddEmployeeSuccessModal'
  )
);
const AddIndividualDrawer = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "AddIndividualDrawer" */ 'features/addTeam/AddIndividualDrawer'
  )
);
const WebMessenger = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MessengerRootV2" */ 'features/messengerv2/MessengerRoot'
  )
);
const TaxEntryDrawer = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "TaxEntryDrawer" */ 'features/team/drawers/TaxEntryDrawer'
  )
);
const TimecardModalV3 = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "TimecardModalV3" */ 'features/timesheets/TimesheetsPage/TimecardModal'
  )
);

@connect(
  (state, props) => ({
    currentUser: getCurrentUser(state, props),
    showPayAnywhereTimesheetsModal: getShowPayAnywhereTimesheetsModal(
      state,
      props
    ),
    showQuickbooksAuthModal: getShowQuickbooksAuthModal(state, props),
    modalAnnouncementForCurrentPath: getModalAnnouncementForCurrentPath(
      state,
      props
    ),
    bannerAnnouncementForCurrentPath: getBannerAnnouncementForCurrentPath(
      state,
      props
    ),
    showAddTeamDrawers: getShowAddTeamDrawers(state, props),
    showPasswordResetModal: getShowPasswordResetModal(state, props),
    shouldShowPastDueSubscriptionDialog:
      selectShouldShowPastDueSubscriptionDialog(state),
    shouldShowNextIntegrationDialog:
      selectShouldShowNextIntegrationDialog(state),
    TipManagerCheckoutDrawerIsOpen: selectTipManagerCheckoutDrawerIsOpen(state),
    TaskManagerCheckoutDrawerIsOpen:
      selectTaskManagerCheckoutDrawerIsOpen(state),
    showC2CDialogFromURL: selectShowC2CDialogFromURL(state),
  }),
  {
    setAnnouncementCanShowToFalse,
  }
)
@cxHelpers('GlobalComponents')
// If you are adding a new Banner/Footer/Loader/Drawer and don't need a redux action to fire
// create your *Global* component here.
// If you are looking to create a new modal, please follow the patterns laid-out
// in /modals/RootModal/RootModal.jsx
export default class GlobalComponents extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    showPayAnywhereTimesheetsModal: PropTypes.bool,
    showQuickbooksAuthModal: PropTypes.bool,
    modalAnnouncementForCurrentPath: ImmutablePropTypes.map,
    bannerAnnouncementForCurrentPath: ImmutablePropTypes.map,
    showAddTeamDrawers: PropTypes.bool,
    showPasswordResetModal: PropTypes.bool,
    shouldShowPastDueSubscriptionDialog: PropTypes.bool,
    shouldShowNextIntegrationDialog: PropTypes.bool,
    TipManagerCheckoutDrawerIsOpen: PropTypes.bool,
    TaskManagerCheckoutDrawerIsOpen: PropTypes.bool,
    showC2CDialogFromURL: PropTypes.bool,
  };

  print = () => window.print();

  render() {
    const { currentUser } = this.props;

    return (
      <div className={this.cx()}>
        {/* For views built in backbone; when rendered by browsers built on Chromium;
        window.print() completely crashes the tab. However, invoking window.print() from
        a react component opens the print preview without issue. That's the purpose of
        the invisible div below. */}
        <div
          className="hidden-print-button"
          style={{ display: 'none' }}
          onClick={this.print}
        />
        <Suspense fallback={false}>
          <Route
            exact
            path={scheduleBuilderRoute()}
            component={lazy(() =>
              import(
                /* webpackPreload: true, webpackChunkName: "schedule_builder-global" */ 'features/nav/GlobalComponents/ScheduleBuilder'
              )
            )}
          />
          <Route
            path={timesheetsRoute()}
            component={lazy(() =>
              import(
                /* webpackPreload: true, webpackChunkName: "timesheets-global" */ 'features/nav/GlobalComponents/Timesheets'
              )
            )}
          />

          <AddEmployeeWarningModal />
          <AddEmployeeSuccessModal />
          {this.props.shouldShowPastDueSubscriptionDialog && (
            <PastDueSubscriptionDialog />
          )}
          {this.props.shouldShowNextIntegrationDialog && (
            <NextIntegrationDialog />
          )}
          {this.props.showC2CDialogFromURL && (
            <ClickToCallDialog openedFromURLParam />
          )}
          <AddTeamDrawer />
          <TaxEntryDrawer />
          {this.props.showAddTeamDrawers && (
            <Fragment>
              <ChooseHowDrawer />
              <AddIndividualDrawer />
            </Fragment>
          )}

          {/* ONLY IF A USER IS SIGNED IN */}
          {currentUser?.get('id') && (
            <>
              <WebMessenger />
              <TimecardModalV3 />
            </>
          )}

          <NotificationsView />
          <ActionsListDrawer />
          {this.props.showPayAnywhereTimesheetsModal && (
            <PayAnywhereTimesheetsModal />
          )}
          {this.props.showQuickbooksAuthModal && <QuickbooksAuthModal />}
          {this.props.showPasswordResetModal && <PasswordResetModal />}
          <AnnouncementModal
            announcement={this.props.modalAnnouncementForCurrentPath}
            setAnnouncementCanShowToFalse={
              this.props.setAnnouncementCanShowToFalse
            }
          />
          <AnnouncementBanner
            announcement={this.props.bannerAnnouncementForCurrentPath}
            setAnnouncementCanShowToFalse={
              this.props.setAnnouncementCanShowToFalse
            }
          />
          <BottomSheetDialog>
            <BottomMobileInterstitial />
          </BottomSheetDialog>
          {this.props.TipManagerCheckoutDrawerIsOpen && (
            <TipManagerCheckoutDrawer />
          )}
          {this.props.TaskManagerCheckoutDrawerIsOpen && (
            <TaskManagerCheckoutDrawer />
          )}
        </Suspense>
      </div>
    );
  }
}
