/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';

import { getCurrentLocationName } from 'selectors/session';

import {
  fetchProductTaskManager as getFetchProductTaskManager,
  fetchTaskManagerSubscriptions as getFetchTaskManagerSubscriptions,
} from 'features/managePlan/actions';
import { selectProductTaskManagerPrice } from 'features/managePlan/selectors';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { withUxRoot } from 'util/uxEvents';

import { selectInitialViewKey } from './selectors';
import { closeTaskManagerCheckoutDrawer as getCloseTaskManagerCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('TaskManagerCheckoutDrawer');

export const TaskManagerCheckoutDrawer = () => {
  const subscriptionType = 'task_manager';
  const dispatch = useDispatch();
  const closeTaskManagerCheckoutDrawer = useCallback(() => {
    dispatch(getCloseTaskManagerCheckoutDrawer());
  }, [dispatch]);
  const fetchProductTaskManager = useCallback(() => {
    dispatch(getFetchProductTaskManager());
  }, [dispatch]);

  const fetchTaskManagerSubscriptions = useCallback(() => {
    dispatch(getFetchTaskManagerSubscriptions());
  }, [dispatch]);

  const initialViewKey = useSelector(selectInitialViewKey);
  const currentLocationName = useSelector(getCurrentLocationName);
  const price = useSelector(selectProductTaskManagerPrice);

  const [currentViewKey, setCurrentViewKey] = useState(initialViewKey);
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];
  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
    fetchTaskManagerSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetchLocationTaskPolicies();
    fetchProductTaskManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeTaskManagerCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          closeDrawer={closeTaskManagerCheckoutDrawer}
          locationName={currentLocationName}
          price={price}
          subscriptionType={subscriptionType}
        />
      </Box>
    </Drawer>
  );
};
export default withUxRoot({
  productArea: PRODUCT_AREAS.TASK_MANAGER,
  eventCategory: EVENT_CATEGORIES.CHECKOUT,
})(TaskManagerCheckoutDrawer);
