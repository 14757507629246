import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { useTransition } from '@react-spring/web';

import { DRAWER_COMPONENTS_NAME as TIP_POOL_DRAWER_COMPONENTS_NAMES } from 'features/bottomDrawers/TipPoolDrawer/constants';
import { NBK_DOWNGRADE_BOTTOM_DRAWER } from 'features/earlyLife/NewBusinessKit/constants';
import { DOWNGRADE_BOTTOM_DRAWER } from 'features/monetization/DowngradeBottomDrawer/constants';
import { ENFORCEMENT_BOTTOM_DRAWER } from 'features/monetization/EnforcementBottomDrawer/constants';
import { DRAWER_COMPONENTS_NAME as ONBOARDING_DRAWER_COMPONENTS_NAMES } from 'features/onboarding/constants';
import { PAYROLL_DRAWER_COMPONENTS_NAMES } from 'features/payroll/constants';

import LoadingDrawer from '../LoadingDrawer';
import {
  getBottomDrawerType,
  getCompletionEventName,
  getDrawerProps,
  getInitiatedFrom,
  getModuleOrder,
  getShouldFireCompletionEvent,
  getShouldFireModuleComplete,
  getTrackingDetails,
} from '../selectors';

const {
  BULK_ADD_EMPLOYEE,
  SCHEDULER_CARD,
  TIME_PAY_CARD,
  PAY_YOUR_TEAM_CARD,
  TIME_CLOCK_DRAWER,
} = ONBOARDING_DRAWER_COMPONENTS_NAMES;

const {
  CREATE_TIP_POOL_DRAWER,
  EDIT_TIP_POOL_DRAWER,
  CALCULATE_TIP_OUTS_DRAWER,
} = TIP_POOL_DRAWER_COMPONENTS_NAMES;

const {
  PAYROLL_SUMMARY_DRAWER,
  PAYROLL_TEAM_MEMBERS_DRAWER,
  PAYROLL_MAIL_TAX_FORMS_DRAWER,
  COMPANY_DEFINED_ATTRIBUTES_DRAWER,
  PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER,
} = PAYROLL_DRAWER_COMPONENTS_NAMES;

const DRAWER_COMPONENTS = {
  LOADING_DRAWER: LoadingDrawer,
  [BULK_ADD_EMPLOYEE]: lazy(() => import('../BulkAddEmployeeDrawer')),
  [PAYROLL_SUMMARY_DRAWER]: lazy(() => import('../PayrollSummaryDrawer')),
  [PAYROLL_MAIL_TAX_FORMS_DRAWER]: lazy(() => import('../MailTaxFormsDrawer')),
  [PAYROLL_TEAM_MEMBERS_DRAWER]: lazy(() =>
    import('../PayrollTeamMembersDrawer')
  ),
  [COMPANY_DEFINED_ATTRIBUTES_DRAWER]: lazy(() =>
    import('../CompanyDefinedAttributesDrawer')
  ),
  [PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER]: lazy(() =>
    import('../PayrollPredictedFundingFailureDrawer')
  ),
  [SCHEDULER_CARD]: lazy(() => import('../SchedulerCardDrawer')),
  [TIME_PAY_CARD]: lazy(() => import('../TimePayCardDrawer')),
  [PAY_YOUR_TEAM_CARD]: lazy(() => import('../PayYourTeamCardDrawer')),
  [TIME_CLOCK_DRAWER]: lazy(() => import('../TimeClockDrawer')),
  [DOWNGRADE_BOTTOM_DRAWER]: lazy(() =>
    import('features/monetization/DowngradeBottomDrawer')
  ),
  [ENFORCEMENT_BOTTOM_DRAWER]: lazy(() =>
    import('features/monetization/EnforcementBottomDrawer')
  ),
  [CREATE_TIP_POOL_DRAWER]: lazy(() => import('../TipPoolDrawer/CreateDrawer')),
  [EDIT_TIP_POOL_DRAWER]: lazy(() => import('../TipPoolDrawer/EditDrawer')),
  [CALCULATE_TIP_OUTS_DRAWER]: lazy(() =>
    import('../TipPoolDrawer/CalculateTipOutsDrawer')
  ),
  [NBK_DOWNGRADE_BOTTOM_DRAWER]: lazy(() =>
    import(
      'features/earlyLife/NewBusinessKit/components/NBKDowngradeBottomDrawer'
    )
  ),
};

const springConfig = key => {
  switch (key) {
    case 'bgOpacity':
      return { duration: 300 };
    default:
      return { mass: 4, friction: 50, tension: 180 };
  }
};

const Root = ({
  bottomDrawerType,
  initiatedFrom,
  completionEventName,
  shouldFireCompletionEvent,
  trackingDetails,
  shouldFireModuleComplete,
  moduleOrder,
  drawerProps,
}) => {
  const transitions = useTransition(bottomDrawerType, {
    from: { cardTranslateY: '100%', bgOpacity: 0, bgPointerEvents: 'none' },
    enter: { cardTranslateY: '0%', bgOpacity: 1, bgPointerEvents: 'all' },
    leave: { cardTranslateY: '100%', bgOpacity: 0, bgPointerEvents: 'none' },
    config: springConfig,
  });

  return transitions((animations, item, { key }) => {
    const DrawerComponent = DRAWER_COMPONENTS[item];

    return DrawerComponent ? (
      <Suspense fallback={<LoadingDrawer animations={animations} />} key={key}>
        <DrawerComponent
          animations={animations}
          initiatedFrom={initiatedFrom}
          completionEventName={completionEventName}
          shouldFireCompletionEvent={shouldFireCompletionEvent}
          trackingDetails={trackingDetails}
          shouldFireModuleComplete={shouldFireModuleComplete}
          moduleOrder={moduleOrder}
          drawerProps={drawerProps}
        />
      </Suspense>
    ) : null;
  });
};

export default connect(state => ({
  bottomDrawerType: getBottomDrawerType(state),
  initiatedFrom: getInitiatedFrom(state),
  completionEventName: getCompletionEventName(state),
  shouldFireCompletionEvent: getShouldFireCompletionEvent(state),
  trackingDetails: getTrackingDetails(state),
  shouldFireModuleComplete: getShouldFireModuleComplete(state),
  moduleOrder: getModuleOrder(state),
  drawerProps: getDrawerProps(state),
}))(Root);
