import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import {
  ACTION_CONFLICT_CHECK,
  ACTION_FETCH,
  ACTION_POST,
} from 'fe-core/module-constants';
import { buildPayload, createResource } from 'fe-core/Resources';
import { get, post } from 'fe-core/Resources/actions';

const Shift = createResource({
  name: 'shift',
  path: '/schedule_builder/shifts',
  schema: {
    savePending: true,
    attributes: [
      'color',
      'roleName',
      'startAt',
      'endAt',
      'time',
      'date',
      'conflict',
      'isOpen',
      'isPublished',
      'note',
      'parentShiftId',
      'syncError',
    ],
    relationships: {
      owner: {
        defaultType: 'job',
        relationshipType: BELONGS_TO,
        inverseOf: 'shifts',
      },
      shiftConflicts: {
        inverseOf: 'shift',
        relationshipType: HAS_MANY,
      },
      createsConflicts: {
        inverseOf: 'conflict',
        relationshipType: HAS_MANY,
      },
      claimRequests: {
        inverseOf: 'shift',
        relationshipType: HAS_MANY,
      },
      openShiftTotals: {
        inverseOf: 'shift',
        relationshipType: HAS_MANY,
      },
      shiftLaborTotals: {
        inverseOf: 'shift',
        relationshipType: HAS_MANY,
      },
      role: {
        relationshipType: BELONGS_TO,
        inverseOf: 'shifts',
      },
    },
  },
});

Shift.conflictCheck = ({ shifts }) =>
  post({
    path: '/schedule_builder/shifts/conflict_check.json',
    name: Shift.name,
    action: ACTION_CONFLICT_CHECK,
    payload: {
      shifts: {
        data: shifts.map(shift => {
          const { id, owner, startAt, endAt } = shift;
          const json = buildPayload(Shift.name, {
            id,
            owner,
            startAt,
            endAt,
          }).data;
          json.attributes.id = id;
          return json;
        }),
      },
    },
  });

Shift.fetch = id =>
  get({
    path: `/schedule_builder/shifts/${id}`,
    name: Shift.name,
    action: ACTION_FETCH,
  });

Shift.claim = id =>
  post({
    path: `/schedule_builder/shifts/${id}/claim_requests`,
    name: Shift.name,
    action: ACTION_POST,
  });

export default Shift;
