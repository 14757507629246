import { Map } from 'immutable';

import { actionTypes } from 'actions/modals';

import { actions as billerActions } from 'features/biller';
import * as constants from 'features/modals/constants';

import { createReducer } from 'util/redux';

const INITIAL_STATE = Map({
  modalType: null,
  modalProps: {},
  modalSource: null,
  modalPromoCode: null,
  isAddMoreLocationsModalOpen: false,
  addMoreLocationsStep: 1,
});

export default createReducer(INITIAL_STATE, {
  [actionTypes.SHOW_MODAL]: (state, { payload }) =>
    Map({
      modalType: payload.modalType,
      modalProps: payload.modalProps,
      modalSource: state.getIn(['modalSource']),
      modalPromoCode: state.getIn(['modalPromoCode']),
    }),
  [actionTypes.HIDE_MODAL]: () => INITIAL_STATE,
  [actionTypes.SET_MODAL_SOURCE]: (state, { payload }) =>
    state.merge({
      modalSource: payload.modalSource,
    }),
  [actionTypes.SET_MODAL_PROMO_CODE]: (state, { payload }) =>
    state.merge({
      modalPromoCode: payload.modalPromoCode,
    }),
  [actionTypes.UPDATE_CURRENT_MODAL_PROP]: (state, { payload }) => {
    const modalProps = state.get('modalProps');
    const newModalProps = { ...modalProps, ...payload };
    return state.set('modalProps', newModalProps);
  },
  [billerActions.paymentMethodUpdated]: () => INITIAL_STATE,
  [billerActions.hiringBoostAdded]: (state, { payload }) =>
    Map({
      modalType: constants.BILLER_JOB_REQUEST_CREATED_MODAL,
      modalProps: {
        deprecatedModal: true,
        auto: true,
        noPadding: true,
        i18nKey: payload.i18nKey,
      },
      modalSource: state.get('modalSource'),
      modalPromoCode: state.get('modalPromoCode'),
      isCsModalForInboundCallsBoostOpen: state.get(
        'isCsModalForInboundCallsBoostOpen'
      ),
    }),
});
