import { fromJS, OrderedMap } from 'immutable';

import * as formsUtil from 'util/forms';
import { toI18n } from 'util/i18n';

export const INITIAL_DASHBOARD_STATE = fromJS({
  isFetching: false,
  isSubmittingJobPosting: false,
  pageTipsDrawerOpen: false,
});

export const INITIAL_PROMOTE_STATE = fromJS({
  isFetching: false,
});

export const INITIAL_COMPANY_PROFILE_STATE = fromJS({
  isEditing: false,
  isFetching: false,
});

export const MOBILE_APPLICANT_PROFILE_TAB_VIEW = {
  FORM: 'form',
  APPLICATIONS: 'applications',
};

export const INITIAL_APPLICANT_PROFILE_STATE = fromJS({
  isEditing: false,
  serverErrors: {},
  customRoles: [],
  mobileTabView: MOBILE_APPLICANT_PROFILE_TAB_VIEW.FORM,
});

export const INITIAL_MANAGE_APPLICANTS_STATE = fromJS({
  hireModalIsOpen: false,
  hireModalApplicant: {},
  hiredUserId: null,
  prefillLastMessageId: null,
});

export const INITIAL_COMPANY_PROFILE_FORM_STATE =
  formsUtil.buildInitialFormState({
    id: formsUtil.INITIAL_FIELD_STATE,
    logo: formsUtil.INITIAL_FIELD_STATE,
    cover: formsUtil.INITIAL_FIELD_STATE,
    title: formsUtil.INITIAL_FIELD_STATE,
    description: formsUtil.INITIAL_FIELD_STATE,
    location_profiles: OrderedMap(),
  });

export const INITIAL_JOB_REQUEST_APPLICATION_FORM_STATE = fromJS({
  isSubmitting: false,
});

export const INITIAL_LOCATION_APPLICATION_FORM_STATE = fromJS({
  isSubmitting: false,
});

export const INITIAL_SETTINGS_STATE = fromJS({
  verification_complete: undefined,
});

export const INITIAL_BOOST_UPSELL_STATE = fromJS({
  partners: [],
  boost_base_price: {},
  boost_durations: {},
});

export const LOCATION_PROFILE_FIELDS = [
  'id',
  'location_id',
  'address_1',
  'address_2',
  'city',
  'state',
  'zip',
  'title',
];

export const ROLE_HEADER_OPTION_NAMES = Object.freeze({
  CUSTOM_ROLES: toI18n('hiring.dashboard.post_a_job_custom_role_header'),
  STANDARDIZED_ROLES: toI18n(
    'hiring.dashboard.post_a_job_standardized_role_header'
  ),
  POPULAR_ROLES: toI18n('hiring.dashboard.post_a_job_popular_role_header'),
});

export const APPLICANT_PROFILE_INTRO_CALLOUT_KEY =
  'applicant_profile_welcome_overlay';

export const STANDARDIZED_ROLE_CLASS_NAME = 'StandardizedRole';
export const CUSTOM_ROLE_CLASS_NAME = 'Hiring::CustomRole';
export const APPLICANT_CUSTOM_ROLE_CLASS_NAME = 'Hiring::ApplicantCustomRole';

export const HIRING_WIDGET = Object.freeze({
  APPLICANTS_VIEW: 'applicants',
  MESSAGES_VIEW: 'messages',
  INTERVIEWS_VIEW: 'interviews',
});

export const HIRING_WIDGET_MESSAGES_LIMIT = 3;

export const SET_UP_CALL_PILL = 'set_up_call';
export const LAST_MESSAGE_PILL = 'last_message';
export const PREFILL_PILL_IDS = [
  'interested',
  SET_UP_CALL_PILL,
  LAST_MESSAGE_PILL,
];

export const DASHBOARD_MANAGE_BUTTON_TYPE = 'manage';
export const DASHBOARD_ELLIPSIS_BUTTON_TYPE = 'ellipsis';

export const ARCHIVE_APPLICANTS_MODAL = 'ARCHIVE_APPLICANTS_MODAL';

export const SOURCE_PAYLOAD_MAPPER = {
  'Walk-In': 'homebase_manual_walk_in',
  Referral: 'homebase_manual_referral',
  'Social Media': 'homebase_manual_social_media',
  'Other Job Boards': 'homebase_manual_other_job_boards',
};

export const HOMEBASE = 'homebase';
export const INDEED = 'indeed';
export const ZIP_RECRUITER = 'zip_recruiter';
export const GOOGLE_JOBS = 'google_jobs';
export const CRAIGSLIST = 'craigslist';
export const ZIP_RECRUITER_BOOST = 'zip_recruiter_boost';

export const JOB_REQUEST_AUX_DATA_KEY = 'JOB_REQUEST_AUX_DATA';

export const NEW_JOB_POST_PATH = '/hiring/job_post/new';

export const HIRED_FROM_OPTIONS = [
  'Walk In',
  'Online Job Board',
  'Company Website',
  'Referral',
  'Other',
];
