export const APPLICATION_NOTES_DRAWER_KEY = 'APPLICATION_NOTES';
export const MANAGE_APPLICANTS_ZERO_STATE_DRAWER_KEY =
  'MANAGE_APPLICANTS_ZERO_STATE';
export const TIMESHEETS_ZERO_STATE_DRAWER_KEY = 'TIMESHEETS_ZERO_STATE';
export const PUBLISH_DRAWER_KEY = 'PUBLISH';
export const SHIFTS_SYNC_LEGACY_DRAWER_KEY = 'SHIFTS_SYNC_LEGACY';
export const SEND_TO_PARTNER_DRAWER_KEY = 'SEND_TO_PARTNER';
export const PRINT_DRAWER_KEY = 'PRINT';
export const EMPTY_SCHEDULE_DRAWER_KEY = 'EMPTY_SCHEDULE';
export const SCHEDULE_SETTINGS_DRAWER_KEY = 'SCHEDULE_SETTINGS';
export const TEMPLATES_DRAWER_KEY = 'TEMPLATES';
export const MESSENGER_VIEW_DRAWER_KEY = 'MESSENGER_VIEW';
export const ADD_TEAM_DRAWER_KEY = 'ADD_TEAM';
export const ADD_TEAM_INDIVIDUAL_DRAWER = 'ADD_TEAM_INDIVIDUAL_DRAWER';
export const HIRING_PAGE_TIPS_DRAWER_KEY = 'HIRING_PAGE_TIPS';
export const ADD_APPLICANT_MANUALLY_KEY = 'ADD_APPLICANT_MANUALLY_KEY';
export const NOTIFICATIONS_DRAWER_KEY = 'NOTIFICATIONS_DRAWER';
export const CHOOSE_HOW_DRAWER_KEY = 'CHOOSE_HOW_DRAWER';
export const ACTIONS_LIST_DRAWER_KEY = 'ACTIONS_LIST_DRAWER';
export const CUSTOM_DOCUMENT_DRAWER_KEY = 'CUSTOM_DOCUMENT_DRAWER_KEY';
export const EMPLOYER_DETAILS_DRAWER_KEY = 'EMPLOYER_DETAILS_DRAWER_KEY';
