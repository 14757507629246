import 'theme/base.scss';

import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { detectIncognito } from 'detectincognitojs';
import { useIsEmbedded } from 'hooks/useIsEmbedded';
import PropTypes from 'prop-types';

import ViewContainer from 'features/layout';

import IncognitoModeAlert from 'components/IncognitoModeAlert';

import Routes from './Routes';

const App = ({ store }) => {
  const isEmbedded = useIsEmbedded();
  const [isIncognito, setIsIncognito] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    detectIncognito()
      .then(result => setIsIncognito(result.isPrivate))
      .catch(err => setError(err));
  }, []);

  if (!error && isEmbedded && isIncognito) {
    return <IncognitoModeAlert />;
  }

  return (
    <Provider store={store}>
      <ViewContainer>
        <Routes key={crypto.randomUUID()} />
      </ViewContainer>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
